import Button from "@/components/button";
import {Alert} from "bootstrap";
import debounce from "lodash/debounce";
export default {
    name: "sub-sidepanel",
    components: {
        "neo-button": Button,
    },
    data() {
        return {
            isCollapsed: false,
            searchKey: "",
            selectedUserIndex: 0,
            oldIndex: 0,
        };
    },
    computed: {
        filteredUsers() {
            return this.searchKey === "" ? this.users : this.users.filter((e) => e.full_name.toLowerCase().includes(this.searchKey.toLowerCase()) || e.email.toLowerCase().includes(this.searchKey.toLowerCase()));
        },
    },
    props: {
        users: {
            type: Array,
            require: true,
        },
        forGroups: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        // filteredUsers: {
        //     handler: function(newValue) {
        //       if(newValue[0]){
        //         this.handleUserClick(newValue[0],0)
        //       }
        //     },
        //     deep: false
        // }
    },
    methods: {
        isUserAdmin(user) {
            return user.groups.some((e) => e.name == "admin");
        },
        handleExpand() {
            this.isCollapsed = !this.isCollapsed;
        },
        // handleSearch() {
        //     if (!this.forGroups) {
        //         this.oldIndex=''
        //         if(this.searchKey){
        //             this.selectedUserIndex=''
        //             this.$emit("resetuserClick");
        //         }else{
        //             this.handleUserClick(this.users[0],0)
        //             this.selectedUserIndex=0
        //         }
        //     }
        // },
        handleSearch: debounce(function (value) {
            if (!this.forGroups) {
                this.oldIndex = "";
                if (value) {
                    this.$emit("resetuserClick");
                    if (this.filteredUsers.length) this.handleUserClick(this.filteredUsers[0], 0);
                    this.selectedUserIndex = 0;
                } else {
                    if (this.users.length) this.handleUserClick(this.users[0], 0);
                    this.selectedUserIndex = 0;
                }
            }
        }, 500),
        handleUserClick(user, index) {
            this.selectedUserIndex = index;
            this.users[index].active = true;
            if (this.forGroups) {
                let group = user;
                this.$emit("groupClick", group, index);
            } else {
                this.oldIndex = this.users.findIndex((element) => element.id == user.id);
                this.$emit("userClick", user, index, this.oldIndex);
            }
        },
    },
};
